// Generated by Framer (c01e615)

import { addFonts, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["cPGRZ3AQY"];

const variantClassNames = {cPGRZ3AQY: "framer-v-y7xv8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "cPGRZ3AQY", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-FDWd4", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} background={{alt: "", fit: "fill", sizes: "min(750px, 100vw)", src: new URL("assets/tAqVVIp1Fe90qWzOkwTTUoffZ64.png", import.meta.url).href, srcSet: `${new URL("assets/512/tAqVVIp1Fe90qWzOkwTTUoffZ64.png", import.meta.url).href} 400w, ${new URL("assets/1024/tAqVVIp1Fe90qWzOkwTTUoffZ64.png", import.meta.url).href} 800w, ${new URL("assets/tAqVVIp1Fe90qWzOkwTTUoffZ64.png", import.meta.url).href} 1500w`}} className={cx("framer-y7xv8", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"cPGRZ3AQY"} ref={ref} style={{...style}}><motion.div className={"framer-16h6iz6"} layoutDependency={layoutDependency} layoutId={"chlSLTgtg"}><Image background={{alt: "", fit: "fill", intrinsicHeight: 960, intrinsicWidth: 750, pixelHeight: 1920, pixelWidth: 1500, sizes: "1px", src: new URL("assets/1024/R7QRh7MKnSoq8esSIKDndFi9I.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/R7QRh7MKnSoq8esSIKDndFi9I.jpg", import.meta.url).href} 512w, ${new URL("assets/1024/R7QRh7MKnSoq8esSIKDndFi9I.jpg", import.meta.url).href} 1024w, ${new URL("assets/R7QRh7MKnSoq8esSIKDndFi9I.jpg", import.meta.url).href} 2000w`}} className={"framer-1yem4eq"} data-framer-name={"darita_tiny_face"} layoutDependency={layoutDependency} layoutId={"oHTJVu8a2"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-FDWd4 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-FDWd4 .framer-197aqm2 { display: block; }", ".framer-FDWd4 .framer-y7xv8 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-FDWd4 .framer-16h6iz6 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 960px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 750px; }", ".framer-FDWd4 .framer-1yem4eq { aspect-ratio: 0.78125 / 1; flex: 1 0 0px; height: 1px; overflow: visible; position: relative; width: var(--framer-aspect-ratio-supported, 750px); }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-FDWd4 .framer-y7xv8, .framer-FDWd4 .framer-16h6iz6 { gap: 0px; } .framer-FDWd4 .framer-y7xv8 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-FDWd4 .framer-y7xv8 > :first-child { margin-left: 0px; } .framer-FDWd4 .framer-y7xv8 > :last-child { margin-right: 0px; } .framer-FDWd4 .framer-16h6iz6 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-FDWd4 .framer-16h6iz6 > :first-child { margin-top: 0px; } .framer-FDWd4 .framer-16h6iz6 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 960
 * @framerIntrinsicWidth 750
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 */
const FramerV_FZbJZOu: React.ComponentType<Props> = withCSS(Component, css, "framer-FDWd4") as typeof Component;
export default FramerV_FZbJZOu;

FramerV_FZbJZOu.displayName = "ioi";

FramerV_FZbJZOu.defaultProps = {height: 960, width: 750};

addFonts(FramerV_FZbJZOu, [])